import React from "react";
import Styles from "../../styles/Styles";

const Upicon = (
  <svg
    className="icon"
    fill={Styles.Colors.DefaultFont}
    width="50px"
    height="50px"
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m0 50v-50h100v100h-100zm35.766 6.7617l14.234-14.234 28.516 28.516 3.3203-3.3203c1.8281-1.8242 3.3203-3.3633 3.3203-3.418 0-0.12891-35.078-35.207-35.203-35.207-0.12891 0-35.207 35.078-35.207 35.203 0 0.12891 6.5625 6.6914 6.6875 6.6914 0.054687 0 6.5039-6.4062 14.332-14.234z" />
  </svg>
);

const UlIcon = (
  <svg
    fill="#19294F"
    width="20pt"
    height="20pt"
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m81.801 42c0-22-18-40-40-40-21.801 0-39.801 18-39.801 40s18 40 40 40c22-0.19922 39.801-18 39.801-40zm-39.801 33.801c-18.801 0-34-15.199-34-34 0-18.602 15.199-34 34-34s34 15.199 34 34c-0.19922 18.801-15.398 34-34 34z" />
      <path d="m95.199 76.199-14.199-14.199c-4.1992 8.1992-10.801 14.801-19 19l14.199 14.199c3.8008 3.8008 10 3.8008 13.602 0l5.3984-5.3984c3.8008-3.8008 3.8008-10 0-13.602z" />
      <path d="m61.801 50.398c-13-6.3984-26.398-6.3984-39.602 0-2.3984 1-4 3.3984-4 6v6c0 2.1992 1.8008 4 4 4h39.398c2.1992 0 4-1.8008 4-4v-6c0.003906-2.5977-1.3984-5-3.7969-6z" />
      <path d="m42 42.801c6 0 11-6.1992 11-13.801 0-7-4.3984-11.602-11-11.602s-11 4.6016-11 11.602c0 7.6016 4.8008 13.801 11 13.801z" />
    </g>
  </svg>
);

export { Upicon, UlIcon };
